import axios from "axios";
import { Link } from "react-router-dom";
import { FETCH } from "../../Fetch.js";
import "./delete.scss";
import { useState, useEffect } from "react";

const DeleteProperty = ({ id, setDisplayPopup }) => {
  const [property, setProperty] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const token = localStorage.getItem("TOKEN");

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/property/property/${id}`);
        if (res.data && !loaded) {
          setProperty(res.data);
          setLoaded(true);
        }
      } catch (error) {
        console.error("error to get property" + error);
      }
    })();
  }, [loaded, id, property]);

  const deleteProperty = async (e) => {
    e.preventDefault();
    try {
      const resdeleted = await axios.delete(`${FETCH}/property/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (resdeleted) {
        setDeleted(true);
      }
    } catch (err) {
      return console.error(err);
    }
  };

  return (
    <>
      {token ? (
        <>
          <div className="background"></div>
          <div className="popup">
            {deleted ? (
              <>
                <h2>Property deleted</h2>
                <button className="btn" onClick={() => setDisplayPopup(false)}>
                  Close
                </button>
              </>
            ) : (
              <>
                <h1>Delete</h1>
                <p>Are you sure you want to delete this property :</p>
                <p>
                  Title: <strong>{property.title}</strong>
                </p>
                <p>
                  Status: <strong>{property.status}</strong>
                </p>
                <p>
                  Description: <strong>{property.description}</strong>
                </p>
                <div className="buttons">
                  <button className="btn" onClick={(e) => deleteProperty(e)}>
                    Delete
                  </button>
                  <button
                    className="btn"
                    onClick={() => setDisplayPopup(false)}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h1>Unauthorized</h1>
          <Link to="/login">
            <button className="btn">Back</button>
          </Link>
        </>
      )}
    </>
  );
};

export default DeleteProperty;
