import "./propertyList.scss";
import { FETCH } from "../../Fetch";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DeleteProperty from "../popupDelete/DeleteProperty";

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [id, setId] = useState(undefined);
  const [banner, setBanner] = useState("");
  const [deleteMsg, setDeleteMsg] = useState("");

  const history = useHistory();
  const token = localStorage.getItem("TOKEN");

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/property/properties`);
        if (
          res.data &&
          (JSON.stringify(res.data) !== JSON.stringify(properties) || !loaded)
        ) {
          setProperties(res.data);
          setLoaded(true);
        }
      } catch (error) {
        console.error("error to get properties" + error);
      }
    })();
  }, [properties, displayPopup, loaded]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/banner/get-all`);
        if (res.data[0]) {
          setBanner(res.data[0]._id);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [banner]);

  const logout = () => {
    localStorage.removeItem("TOKEN");
    history.push("/login");
  };

  const deleteBanner = async () => {
    try {
      const deleted = axios.delete(`${FETCH}/banner/delete/${banner}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (deleted) {
        setDeleteMsg("Banner deleted");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="propertyMain">
      {token ? (
        <>
          <div className="top">
            <button className="btn" onClick={() => logout()}>
              Logout
            </button>
            <h1>Properties</h1>
            <Link to="/create">
              <button className="btn">Create Property</button>
            </Link>
          </div>

          {!banner.length ? (
            <Link to="/banner">
              <button className="btn">Create Banner</button>
            </Link>
          ) : (
            <button className="btn" onClick={() => deleteBanner()}>
              Delete Banner
            </button>
          )}
          {deleteMsg && <p>{deleteMsg}</p>}
          <div className="content">
            {properties.length &&
              properties.map((house) => (
                <div className="card" key={house._id}>
                  {house.images && (
                    <div className="imgcontainer">
                      <img
                        className={
                          house.status !== "active" &&
                          ((house.status === "sold" && "sold") ||
                            ((house.status !== "sold" || !house.images[0]) &&
                              "intertain"))
                        }
                        src={house.images[0]}
                        alt={house.images[0] ? "property" : ""}
                      />
                      <h3
                        className={
                          house.status !== "active" || !house.images[0]
                            ? "centertext"
                            : "nodisplay"
                        }
                      >
                        {(!house.images[0] &&
                          house.status === "active" &&
                          "NO PICTURE") ||
                          (house.status === "sold" && "SOLD") ||
                          (house.status === "comingsoon" && "COMING SOON") ||
                          (house.status === "undercontract" &&
                            "UNDER CONTRACT")}
                      </h3>
                      <p className="cornerText">
                        {house.forRent ? "FOR RENT" : "FOR SALE"}
                      </p>
                    </div>
                  )}
                  <h2>{house.title}</h2>
                  <p>
                    {house.category === "residential" &&
                      house.bedroom +
                        " Beds /" +
                        house.bathroom +
                        " Baths /"}{" "}
                    {house.acres} Acres
                  </p>
                  {house.displayDescription && (
                    <p>{house.description.substr(0, 120)}...</p>
                  )}
                  <div className="buttons">
                    <Link to={`/modify/${house._id}`}>
                      <button className="btn">Modify</button>
                    </Link>
                    <button
                      className="btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisplayPopup(true);
                        setId(house._id);
                        console.log(house._id);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  {displayPopup && (
                    <DeleteProperty id={id} setDisplayPopup={setDisplayPopup} />
                  )}
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <h1>Unauthorized</h1>
          <Link to="/login">
            <button className="btn">Back</button>
          </Link>
        </>
      )}
    </div>
  );
};

export default PropertyList;
