import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./list.scss";
import axios from "axios";
import { FETCH } from "../../Fetch";

const ListPage = () => {
  const [houses, setHouses] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const getHouses = await axios.get(`${FETCH}/property/properties`);

        if (
          getHouses &&
          (JSON.stringify(getHouses.data) !== JSON.stringify(houses) || !loaded)
        ) {
          setHouses(getHouses.data);
          setLoaded(true);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [houses, loaded]);

  return (
    <div className="listMain paddingtop">
      <h1>PROPERTIES LIST</h1>
      <div className="content">
        {houses.map((house) => (
          <div className="card" key={house._id}>
            <Link to={`/property/${house._id}`}>
              {house.images && (
                <div className="imgcontainer">
                  <img
                    className={
                      house.status !== "active" &&
                      ((house.status === "sold" && "sold") ||
                        ((house.status !== "sold" || !house.images[0]) &&
                          "intertain"))
                    }
                    src={house.images[0]}
                    alt={house.images[0] ? "property" : ""}
                  />
                  <h3
                    className={
                      house.status !== "active" || !house.images[0]
                        ? "centertext"
                        : "nodisplay"
                    }
                  >
                    {(!house.images[0] &&
                      house.status === "active" &&
                      "NO PICTURE") ||
                      (house.status === "sold" && "SOLD") ||
                      (house.status === "comingsoon" && "COMING SOON") ||
                      (house.status === "undercontract" && "UNDER CONTRACT")}
                  </h3>
                  <p className="cornerText">
                    {house.forRent ? "FOR RENT" : "FOR SALE"}
                  </p>
                </div>
              )}
            </Link>
            <h2>{house.title}</h2>
            <p>
              {house.bedroom > 0 && `${house.bedroom}  Beds / `}{" "}
              {house.bathroom > 0 && `${house.bathroom} Baths /`}
              {house.acres > 0 && `${house.acres} Acres`}
            </p>
            {house.displayDescription && (
              <p>{house.description.substr(0, 120)}...</p>
            )}
            <Link to={`/property/${house._id}`}>
              <button className="btn">See more</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListPage;
