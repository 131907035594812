import { Link } from "react-router-dom";
import "./popnav.scss";

const PopNav = ({ open, setOpen }) => {
  return (
    <ul open={open} className={`popnavUl ${open ? "open" : "close"}`}>
      <li>
        <Link to="/" className="navlinkStyle" onClick={()=> setOpen(false)}>
          Home
        </Link>
      </li>
      <li>
        <Link to="/list" className="navlinkStyle" onClick={()=> setOpen(false)}>
          Properties
        </Link>
      </li>
      <li>
        <Link to="/contact" className="navlinkStyle" onClick={()=> setOpen(false)}>
          Contact
        </Link>
      </li>
      <li>
        <Link to="/login" className="navlinkStyle" onClick={()=> setOpen(false)}>
          Login
        </Link>
      </li>
    </ul>
  );
};

export default PopNav;
