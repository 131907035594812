import axios from "axios";
import { FETCH } from "../../Fetch.js";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./formProperty.scss";
import dayjs from "dayjs";

const CreateProperty = () => {
  const history = useHistory();
  const token = localStorage.getItem("TOKEN");

  const [property, setProperty] = useState({
    title: "",
    status: "",
    category: "",
    bathroom: 0,
    bedroom: 0,
    acres: 0,
    address: "",
    description: "",
    price: 0,
    images: [],
    displayAddress: true,
    displayDescription: true,
    displayPrice: true,
    forRent: false,
    creation_date: dayjs().toISOString()
  });
  const [imgs, setImgs] = useState([]);

  const [confirm, setConfirm] = useState("");
  const [errors, setErrors] = useState({
    emptyTitle: "",
    emptyStatus: "",
    emptyCategory: "",
    emptyDescription: "",
  });

  const submitNewProperty = async (e) => {
    e.preventDefault();
    let newErrors = { ...errors };
    if (property.title === "") newErrors.emptyTitle = "Required";
    if (property.status === "") newErrors.emptyStatus = "Required";
    if (property.category === "") newErrors.emptyCategory = "Required";
    if (property.description === "") newErrors.emptyDescription = "Required";
    if (JSON.stringify(errors) !== JSON.stringify(newErrors)) {
      setErrors(newErrors);
    } else {
      try {
        let formData = new FormData();
        for (var x = 0; x < imgs.length; x++) {
          for (var y = 0; y < imgs[x].length; y++) {
            formData.append("files", imgs[x][y]);
          }
        }

        formData.append("propertyData", JSON.stringify(property));

        await axios.post(`${FETCH}/property/add-property`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setConfirm("Property created");
        history.push("/properties");
      } catch (err) {
        console.log(err);
        setConfirm("Error");
      }
    }
  };

  return (
    <div className="main">
      {token ? (
        <>
          <Link to="/properties">
            <button className="btn">Back</button>
          </Link>
          <h1>Create</h1>
          <form>
            <div className="left">
              <div className="formGroup">
                <label>Title *</label>
                <input
                  type="text"
                  value={property.title}
                  onChange={(e) => {
                    setProperty({ ...property, title: e.target.value });
                    setErrors({ ...errors, emptyTitle: "" });
                  }}
                />
                {errors.emptyTitle !== "" && <p>{errors.emptyTitle}</p>}
              </div>
              <div className="formGroup">
                <label>Status *</label>
                <select
                  name="status"
                  onChange={(e) => {
                    setProperty({ ...property, status: e.target.value });
                    setErrors({ ...errors, emptyStatus: "" });
                  }}
                >
                  <option>...</option>
                  <option value="active">Active</option>
                  <option value="comingsoon">Coming soon</option>
                  <option value="undercontract">Under contact</option>
                  <option value="sold">Sold</option>
                </select>
                {errors.emptyStatus !== "" && <p>{errors.emptyStatus}</p>}
              </div>
              <div className="formGroup">
                <label>Category *</label>
                <select
                  name="category"
                  onChange={(e) => {
                    setProperty({ ...property, category: e.target.value });
                    setErrors({ ...errors, emptyCategory: "" });
                  }}
                >
                  <option>...</option>
                  <option value="residential">Residential</option>
                  <option value="land">Land</option>
                  <option value="commercial">Commercial</option>
                  <option value="farm">Farm</option>
                </select>
                {errors.emptyCategory !== "" && <p>{errors.emptyCategory}</p>}
              </div>
              <div className="onlyCheckbox">
                <label>For rent:</label>
                <input type="checkbox" onChange={(e)=> setProperty({...property, forRent: e.target.checked})} />
              </div>
              <div className="withCheckbox">
                <div className="formGroup">
                  <label>Price</label>
                  <input
                    type="number"
                    value={property.price}
                    onChange={(e) =>
                      setProperty({ ...property, price: e.target.value })
                    }
                  />
                </div>
                <div className="checkbox">
                  <label>Display</label>
                  <input
                    type="checkbox"
                    value={property.displayPrice}
                    checked={property.displayPrice}
                    onChange={(e) =>
                      setProperty({
                        ...property,
                        displayPrice: !property.displayPrice,
                      })
                    }
                  />
                </div>
              </div>
              <div className="withCheckbox">
                <div className="formGroup">
                  <label>Address</label>
                  <input
                    type="text"
                    value={property.address}
                    onChange={(e) =>
                      setProperty({ ...property, address: e.target.value })
                    }
                  />
                </div>
                <div className="checkbox">
                  <label>Display</label>
                  <input
                    type="checkbox"
                    value={property.displayAddress}
                    checked={property.displayAddress}
                    onChange={(e) =>
                      setProperty({
                        ...property,
                        displayAddress: !property.displayAddress,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="formGroup">
                <label>Number of bathroom</label>
                <input
                  type="number"
                  value={property.bathroom}
                  onChange={(e) =>
                    setProperty({ ...property, bathroom: e.target.value })
                  }
                />
              </div>
              <div className="formGroup">
                <label>Number of bedroom</label>
                <input
                  type="number"
                  value={property.bedroom}
                  onChange={(e) =>
                    setProperty({ ...property, bedroom: e.target.value })
                  }
                />
              </div>
              <div className="formGroup">
                <label>Acres</label>
                <input
                  type="number"
                  value={property.acres}
                  onChange={(e) =>
                    setProperty({ ...property, acres: e.target.value })
                  }
                />
              </div>
              <div className="withCheckbox">
                <div className="formGroup">
                  <label>Description *</label>
                  <textarea
                    value={property.description}
                    onChange={(e) =>
                      setProperty({ ...property, description: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="checkbox">
                  <label>Display</label>
                  <input
                    type="checkbox"
                    value={property.displayDescription}
                    checked={property.displayDescription}
                    onChange={(e) =>
                      setProperty({
                        ...property,
                        displayDescription: !property.displayDescription,
                      })
                    }
                  />
                </div>
                {errors.emptyDescription !== "" && (
                  <p>{errors.emptyDescription}</p>
                )}
              </div>
              <input
                type="file"
                multiple
                onChange={(e) => {
                  setImgs([...imgs, e.target.files]);
                }}
              />
            </div>
            {imgs.map((img) => (
              <p>{img.name}</p>
            ))}
          </form>
          <button className="btn" onClick={(e) => submitNewProperty(e)}>
            Create property
          </button>
          {confirm !== "" && <p>{confirm}</p>}
        </>
      ) : (
        <>
          <h1>Unauthorized</h1>
          <Link to="/login">
            <button className="btn">Back</button>
          </Link>
        </>
      )}
    </div>
  );
};

export default CreateProperty;
