import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FETCH } from "../../Fetch.js";
import "./login.scss";

const Login = () => {
  const history = useHistory();
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  const LogingIn = async (e) => {
    e.preventDefault();
    try {
      if (loginData.email.length && loginData.password.length) {
        const connection = await axios.post(`${FETCH}/user/login`, loginData);

        if(connection){
          localStorage.setItem("TOKEN", connection.data);
          history.push('/properties');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="loginMain">
      <h1>Login</h1>
      <form>
        <div className="formGroup">
          <label>Email</label>
          <input
            type="email"
            onChange={(e) =>
              setLoginData({ ...loginData, email: e.target.value })
            }
          />
        </div>
        <div className="formGroup">
          <label>Password</label>
          <input
            type="password"
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
          />
        </div>
          <button className="btn" onClick={(e)=> LogingIn(e)}>Submit</button>
      </form>
    </div>
  );
};

export default Login;
