import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./home.scss";
import bgvideo from "../../assets/bgvideo.mp4";
import Logo from "../../assets/images/logo1-gold.png";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="homeMain">
      <video autoPlay loop muted="muted" className="video">
        <source src={bgvideo} type="video/mp4" />
      </video>
      <div className="content">
        <img src={Logo} alt="falcon properties" />
        <p>
          Falcon Properties is a Boutique Brokerage Firm W/ Almost 40 Years of
          Residential, Commercial, Multi Family, Land, Farms, Development, New
          Construction Waterfront, Leasing and all facets of Real Estate.
        </p>
        <Link to="/list">
          <button className="btn">View properties</button>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
