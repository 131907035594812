import "./App.scss";
import HomePage from "./components/home/Home";
import ContactPage from "./components/contact/Contact";
import Navbar from "./components/navbar/Navbar";
import ListPage from "./components/houseAndList/List";
import HousePage from "./components/houseAndList/House";
import Footer from "./components/footer/Footer";
import Banner from "./components/banner/Banner";
import Login from "./components/login/Login";
import PropertyList from "./components/propertyList-admin/PropertyList";
import ModifyProperty from "./components/adminForms/ModifyProperty";
import CreateProperty from "./components/adminForms/createProperty";
import BannerForm from "./components/adminForms/BannerForm";
import { Switch, Route } from "react-router-dom";
import axios from "axios";
import { FETCH } from "./Fetch";
import { useState, useEffect } from "react";

function App() {
  const [displayBanner, setDisplayBanner] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${FETCH}/banner/get-all`);
        if (res.data[0]) {
          setDisplayBanner(res.data[0].display);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [displayBanner]);

  const MainWebsite = () => {
    return (
      <div className="App">
        <Navbar />
        {displayBanner && <Banner />}
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/list" component={ListPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/property/:id" component={HousePage} />
          <Route path="/login" component={Login} />
        </Switch>
        <Footer />
      </div>
    );
  };

  return (
    <div className="App">
      <Switch>
        <Route path="/properties" component={PropertyList} />
        <Route path="/modify/:id" component={ModifyProperty} />
        <Route path="/create" component={CreateProperty} />
        <Route path="/banner" component={BannerForm} />
        <Route component={MainWebsite} />
      </Switch>
    </div>
  );
}

export default App;
